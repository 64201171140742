<template>
  <div
    class="abl-friction px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <img
          src="@/assets/TestEquipment/Friction-Machine-min.png"
          width="600"
          height="384"
          alt="ABL Friction Machine"
          title="ABL Friction Machine"
          class="img-responsive wp-image-12388"
        />
        <PanelGroupComponent :panels="panels" />
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="SMS ABL Friction Machine" size="large" />
        <Paragraph
          ><p>
            Safety Management Services, Inc. (SMS™) manufactures the ABL
            Friction Test Machine for clients who require in-house testing
            capability to determine energetic material response to friction
            energy stimulus.&nbsp; The ABL Friction machine is one of the
            industries core sensitivity test machines for producing energetic
            material sensitivity data.
          </p>
          <p>
            The ABL Friction Test is designed to impart a known (downward) force
            onto the test sample while moving two control surfaces relative to
            one another, at a known velocity. &nbsp;This is designed to simulate
            an in-process scenario where two objects have energetic material
            captured between them and slide past each other creating a friction
            event.&nbsp; For the ABL Friction test, an energetic test sample is
            placed on a steel plate (anvil); a steel disc is then lowered into
            contact with the test sample.&nbsp; The steel disc is pressed onto
            the sample with a hydraulic ram until the desired force is
            achieved.&nbsp; The steel plate is then struck by a swing hammer
            that moves the plate 1 inch at the designated velocity.&nbsp; The
            velocity of the plate is controlled by the drop height of the swing
            arm.&nbsp; For low-speed measurements (0 – 3 feet/sec) the anvil is
            moved by attachment to a chain pull drive system.&nbsp; The speed of
            the anvil is controlled by using a variable speed motor to drive the
            chain.
          </p>
          <p>
            Reaction of the material (go vs. no-go) is determined by operator
            observation, detection of decomposition by a gas analyzer, or
            utilizing the SMS developed
            <router-link
              class="link"
              title="GoDetect-ESD"
              to="/test-equipment/godetect"
              >GoDetect™</router-link
            >
            high speed video capture system.&nbsp; The ABL Friction machine is
            capable of producing sensitivity data in engineering units for
            direct correlation to in-process energy. The sensitivity data is
            used to determine critical safety parameters related to
            manufacturing and handling of the materials being tested.
          </p>
          <p>
            The ABL Friction machine produced by SMS has been adopted by both
            the UN and the ET Users’ Group (Explosive Testing Users’ Group,
            www.etusersgroup.org) due to the following:
          </p>
          <ol>
            <li>
              Compliance to established industry standards for test methodology
            </li>
            <li>Applicability to historical data</li>
            <li>
              High level of fabrication controls (i.e. flatness, hardness,
              surface finish)
            </li>
            <li>
              Capable of producing data in engineering units for in-process
              energy comparison
            </li>
            <li>
              Standardized operating procedures for controlling test variables
            </li>
            <li>Equipment calibration</li>
            <li>
              Verified and repeatable test results through a lab validation
              process
            </li>
          </ol>
        </Paragraph>
        <a
          class="heading-link"
          style="float:left;"
          href="https://firebasestorage.googleapis.com/v0/b/smsenergetics.appspot.com/o/2014-SMS-Friction-Machine-Standard-Features_C.pdf?alt=media&token=874820a9-3432-43c2-8801-0fe72f89a8d0"
          target="_blank"
        >
          <div class="h-link">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="book"
              class="svg-inline--fa fa-book fa-w-14"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M448 360V24c0-13.3-10.7-24-24-24H96C43 0 0 43 0 96v320c0 53 43 96 96 96h328c13.3 0 24-10.7 24-24v-16c0-7.5-3.5-14.3-8.9-18.7-4.2-15.4-4.2-59.3 0-74.7 5.4-4.3 8.9-11.1 8.9-18.6zM128 134c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm0 64c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm253.4 250H96c-17.7 0-32-14.3-32-32 0-17.6 14.4-32 32-32h285.4c-1.9 17.1-1.9 46.9 0 64z"
              ></path>
            </svg>
            <h2
              class="content-box-heading fusion-responsive-typography-calculated"
              style="display:inline; font-size: 18px; line-height: 1.28; --fontSize:18; --minFontSize:18;"
              data-fontsize="18"
              data-lineheight="23.04px"
            >
              SMS ABL Friction Machine Technical Specifications
            </h2>
          </div></a
        >
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";
import PanelGroupComponent from "@/components/PanelGroupComponent";

export default {
  metaInfo: {
    title: "ABL Friction",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "The ABL friction machine is designed to simulate two objects with energetic material captured between them sliding past each other, creating a friction event. This machine has been adopted by the UN and ET Users Group methodologies."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph,
    PanelGroupComponent
  },
  data: () => {
    return {
      panels: [
        {
          content1: `<div style="position: relative;width: 100%;padding-bottom: 56.25%;"><iframe src="https://player.vimeo.com/video/489987440" style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;border: 0;" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div>`,
          title: "ABL Friction Test Machine"
        },
        {
          content1: `<div style="position: relative;width: 100%;padding-bottom: 56.25%;"><iframe src="https://player.vimeo.com/video/489987961" style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;border: 0;" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div>`,
          title: "High-Speed Video of Friction Sensitivity Testing"
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.abl-friction {
  .link {
    color: #aa222c;
  }
  .h-link:hover {
    color: #aa222c;
    .fa-book {
      color: #aa222c;
    }
  }
  .fa-book {
    color: black;
    width: 18px;
    display: inline;
  }
}
</style>
